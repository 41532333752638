import CoverLetter from "../Entities/CoverLetter";
import * as Yup from "yup";
import crudApi from "@serie3/common/api/crudApi";
import { CoverLetterConfig } from "../Resources/Endpoints";
import listHandler from "@serie3/common/Domain/listHandler";

const createCoverletterForm = (
  dataList = null,
  setDataFunction = null,
  setShowEdit = null
) => {
  const validationSchema = Yup.object({
    title: Yup.string().required("Required"),
    text: Yup.string().required("Required"),
  });

  const onSubmit = async (values, actions) => {
    const payload = JSON.stringify(values);
    let response;
    if (values.id > 0) {
      response = await crudApi.edit(CoverLetterConfig.endpoints.edit, payload);

      if (dataList !== null) {
        listHandler.updateList(
          response,
          JSON.stringify(response.data),
          dataList,
          setDataFunction
        );
      }
    } else {
      response = await crudApi.createNew(
        CoverLetterConfig.endpoints.new,
        payload
      );

      if (dataList !== null) {
        listHandler.newToList(response, dataList, setDataFunction, null);
      }
    }
    if (response.status === 200 && typeof setShowEdit === "function") {
      setShowEdit(false);
    }
  };

  return {
    initialValues: CoverLetter,
    validationSchema: validationSchema,
    onSubmit: (values, actions) => {
      onSubmit(values, actions);
    },
  };
};

const setEditData = (editData) => {
  let Coverletter = {
    id: editData.id,
    title: editData.title,
    text: editData.text,
    formal: editData.formal,
    active: editData.active,
    cover_letter_language: editData.cover_letter_language,
  };

  return Coverletter;
};

export default { createCoverletterForm, setEditData };
