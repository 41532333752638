import Card from "../Shared/Card";
import { Row, Col, CardHeader, CardBody } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import ColorCard from "../Shared/Cards/ColoredCards/ColorCard";
import Table from "../Shared/Table/Table";
import ContentWrapper from "../Shared/Basics/ContentWrapper";
import ApplicatonRow from "./Dashboard/ApplicatonRow";
import { useState, useEffect } from "react";
import crudApi from "@serie3/common/api/crudApi";
import {
  ApplicationConfig,
  ProfileConfig,
} from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import { useNavigate } from "react-router-dom";
import NewButtonWithIcon from "../Shared/Buttons/NewButtonWithIcon";
import {
  createNewProfile,
  createNewApplicaton,
} from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/Buttons";
import ApplicationModal from "./Applications/Subcomponents/ApplicationModal";
import { calculateDashboardNumbers } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Domain/CalculateDashboardNumber";
import LoadApplications from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/LoadApplications";
import {
  editAction,
  deleteAction,
  removeFromList,
} from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/ApplicationCrudActions";

const Dashboard = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [applications, setApplications] = useState([]);
  const [followUps, setfollowUps] = useState([]);
  const [isLoaded, setIsLoaded] = useState(false);
  const [profiles, setProfiles] = useState([]);
  const [showPayModal, setShowPayModal] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editApplication, setEditApplication] = useState(null);
  const [dashboardNumbers, setDashboardNumbers] = useState({
    todo: 0,
    running: 0,
    done: 0,
  });
  const [updateView, setUpdateView] = useState(false);

  useEffect(() => {
    const laodDashboardData = async () => {
      let allApplications = await LoadApplications(setApplications);

      const endpoint = ProfileConfig.endpoints.getAll;
      crudApi.getAll(endpoint, setProfiles, ProfileConfig.datafield);
      if (window.location.search.length > 0) {
        let applicationData = window.location.search;
        if (applicationData.startsWith("?")) {
          applicationData = applicationData.slice(1);
        }

        let pairs = applicationData.split("&");

        /** @type {{ job_title?: string }} */
        let result = {};

        pairs.forEach((pair) => {
          let [key, value] = pair.split("=");
          result[decodeURIComponent(key)] = decodeURIComponent(value);
        });

        if (
          "job_title" in result &&
          result["job_title"] !== "undefined" &&
          result["job_title"].length > 0
        ) {
          const payload = JSON.stringify(result);
          const applicationResponse = await crudApi.createNew(
            ApplicationConfig.endpoints.new,
            payload
          );

          if (applicationResponse.status === 200) {
            allApplications.unshift(applicationResponse.data);
            navigate("/");
          }
        } else {
          setEditApplication(result);
          setShowForm(true);
        }
      }

      setDashboardNumbers({ ...calculateDashboardNumbers(applications) });
      setIsLoaded(false);

      // Load the Applications that need a followup
      crudApi.getAll(
        ApplicationConfig.endpoints.followUp,
        setfollowUps,
        ApplicationConfig.datafield
      );
    };

    laodDashboardData();
    const parseData = async () => {};
    parseData();
  }, []);

  useEffect(() => {
    if (Array.isArray(applications) && applications.length > 0) {
      setDashboardNumbers({ ...calculateDashboardNumbers(applications) });
    }
  }, [applications, updateView]);

  const profileText = profiles.length + "/2";

  return (
    <>
      <ApplicationModal
        show={showForm}
        setShow={setShowForm}
        applications={applications}
        setApplications={setApplications}
        application={editApplication}
      />
      <ContentWrapper
        title={t("Backend.Dashboard.Title")}
        welcomeText={t("Backend.Dashboard.Subtitle")}
        titleText={t("Backend.Dashboard.DashboardText")}
      >
        <Row>
          <Col>
            <div className="mt-4 mb-3 d-flex flex-wrap gap-2">
              <NewButtonWithIcon
                title={t("Backend.Buttons.NewProfile")}
                showPayModal={showPayModal}
                setShowPayModal={setShowPayModal}
                handleClick={() => {
                  createNewProfile(setShowPayModal, navigate);
                }}
              />

              <NewButtonWithIcon
                title={t("Backend.Buttons.NewApplication")}
                showPayModal={showPayModal}
                setShowPayModal={setShowPayModal}
                color={"info"}
                handleClick={() => {
                  createNewApplicaton(setShowPayModal, setShowForm);
                }}
              />
            </div>
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <Card title={t("Backend.Dashboard.TodoTableHeader")}>
              <Table style={{ overflow: "visible", paddingBottom: "20px" }}>
                <thead>
                  <tr>
                    <th style={{ width: "40" }}>
                      {t("Backend.Dashboard.Table.Header.Days")}
                    </th>
                    <th>{t("Backend.Dashboard.Table.Header.Title")}</th>
                    <th>{t("Backend.Dashboard.Table.Header.Company")}</th>
                    <th>{t("Backend.Dashboard.Table.Header.Link")}</th>
                    <th className="text-center">
                      {t("Backend.Dashboard.Table.Header.Status")}
                    </th>
                    <th className="text-center">
                      {t("Backend.Dashboard.Table.Header.Actions")}
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(applications) &&
                    applications.length > 0 &&
                    applications.map((application, index) => {
                      return (
                        <ApplicatonRow
                          application={application}
                          index={index}
                          key={application.id}
                          deleteAction={() => {
                            deleteAction(
                              application.id,
                              index,
                              setApplications,
                              applications
                            );
                          }}
                          editAction={() => {
                            editAction(
                              application,
                              setEditApplication,
                              setShowForm
                            );
                          }}
                          updateView={updateView}
                          setUpdateView={setUpdateView}
                          removeFromList={() => {
                            removeFromList(
                              application,
                              applications,
                              setApplications
                            );
                          }}
                        />
                      );
                    })}
                </tbody>
              </Table>
            </Card>
          </Col>
          <Col sm={4}>
            <Row>
              <Col sm={6}>
                <ColorCard
                  title={t("Backend.Dashboard.AvailableProfiles")}
                  text={profileText}
                />
              </Col>
              <Col sm={6}>
                <ColorCard
                  title={t("Backend.Dashboard.ToDoApplications")}
                  color="purple"
                  text={dashboardNumbers.todo}
                />
              </Col>
            </Row>
            <Row>
              <Col sm={6}>
                <ColorCard
                  title={t("Backend.Dashboard.RunningApplications")}
                  color="warning"
                  text={dashboardNumbers.running}
                />
              </Col>
              <Col sm={6}>
                <ColorCard
                  title={t("Backend.Dashboard.LostApplications")}
                  color="info"
                  text={dashboardNumbers.done}
                />
              </Col>
            </Row>
            <Row>
              <Col>
                <Card>
                  <CardHeader>
                    <h5>{t("Backend.Dashboard.FollowUp")}</h5>
                  </CardHeader>
                  <CardBody>
                    <Table>
                      <thead>
                        <tr>
                          <th>{t("Backend.Dashboard.Table.Header.Title")}</th>
                          <th>{t("Backend.Dashboard.Table.Header.Date")}</th>
                        </tr>
                      </thead>
                      <tbody>
                        {followUps.map((followup, index) => {
                          return (
                            <tr key={followup.id}>
                              <td> {followup.job_title}</td>
                              <td> {followup.follow_up}</td>
                            </tr>
                          );
                        })}
                      </tbody>
                    </Table>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Col>
        </Row>
      </ContentWrapper>
    </>
  );
};

export default Dashboard;
