import Tabs from "react-bootstrap/Tabs";
import { useState } from "react";

const StyledTabs = ({ children, defaultKey = "", handleKeyChange = null }) => {
  const [key, setKey] = useState(defaultKey);

  const updateKey = (k) => {
    setKey(k);
    if(typeof handleKeyChange === "function"){
      handleKeyChange(k);
    }
  };

  return (
    <div className="tab-base">
      <Tabs
        defaultActiveKey="profile"
        id="uncontrolled-tab-example"
        className="tab-base"
        activeKey={key}
        onSelect={((k) => updateKey(k))}
      >
        {children}
      </Tabs>
    </div>
  );
};

export default StyledTabs;
