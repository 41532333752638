import NewModal from "../../../Shared/Modals/NewModal";
import { useTranslation } from "react-i18next";
import LoadApplications from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/LoadApplications";
import { useState, useEffect } from "react";
import {
  ApplicationConfig,
  CoverLetterConfig,
} from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import ApplicationItem from "../../user/Applications/ApplicationItem";
import Carousel from "react-bootstrap/Carousel";
import Profile from "../../Profiles/Profile";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";
import { Row, Form, Button, Col } from "react-bootstrap";
import removeGenderTags from "@serie3/bewerbungshelferleinapi/Domain/removeGenderTags";
import TextArea from "../../../Shared/Form/TextArea";
import TextField from "../../../Shared/Form/TextField";
import ApplicationCheck from "./ApplicationCheck";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import AIDropdownMenu from "./AIDropdownMenu";
import LoadingModal from "../../../Shared/Modals/LoadingModal";

const ComposeApplicationModal = ({
  show,
  setShow,
  setShowPreview,
  setPreviewId,
  setPreviewProfileTitle,
  isConnected,
  composeApplicationForm,
  composeFormik,
}) => {
  const { t } = useTranslation();
  const [todoApplications, setTodoApplications] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [aiLetter, setAiLetter] = useState("");
  const [checkResults, setCheckResults] = useState([]);
  const [letters, setLetters] = useState([]);
  const [slideIndex, setSlideIndex] = useState(0);
  const [choosenProfile, setChoosenProfile] = useState(null);
  const [application, setApplication] = useState(null);
  const [stepTitle, setStepTitle] = useState("Compose your application");
  const [profileTitle, setProfileTitle] = useState("");
  const [applicationFit, setApplicationFit] = useState(null);
  const [fitColor, setFitColor] = useState(null);
  const [showLoadingModal, setShowLoadingModal] = useState(false);

  const [applicantFullName, setApplicantFullName] = useState("");

  const loadTodoApplications = async () => {
    await LoadApplications(
      setTodoApplications,
      ApplicationConfig.endpoints.getTodo
    );
  };

  const loadProfiles = async () => {
    const endpoint = ProfileConfig.endpoints.getAll;
    await crudApi.getAll(endpoint, setProfiles, "profile");
  };

  const loadLetters = async () => {
    const endpoint = CoverLetterConfig.endpoints.getAll;
    await crudApi.getAll(endpoint, setLetters, CoverLetterConfig.datafield);
  };

  const setStep1 = (application) => {
    setSlideIndex(1);
    setApplication(application);
  };

  const setStep2 = (
    profileId,
    applicationFit,
    applicationFitColor,
    fullName
  ) => {
    setSlideIndex(2);
    setApplicationFit(applicationFit);
    setFitColor(applicationFitColor);
    composeFormik.setFieldValue("profile.id", profileId);
    setApplicantFullName(fullName);

    const mailSubject = t("Backend.Applications.Modal.MailSubject", {
      jobTitle: application.job_title,
    });
    let mailText = t("Backend.Applications.Modal.MailText", {
      jobTitle: application.job_title,
    });
    mailText += fullName;

    application.profile = { id: profileId };
    composeFormik.setFieldValue("application.id", application.id);
    composeFormik.setFieldValue("mail_subject", mailSubject);
    composeFormik.setFieldValue("mail_text", mailText);
    composeFormik.setFieldValue("mail_address", application.mail_address);
  };

  const setStep3 = () => {
    setSlideIndex(3);
  };

  const UseButton = () => {
    return (
      <Col
        className="d-flex justify-content-center align-items-center"
        style={{ color: fitColor }}
      >
        <Button className="btn btn-success">
          {t("Backend.Applications.Buttons.Use")}
        </Button>
      </Col>
    );
  };

  const UpdateProfileTitle = () => {
    return (
      <>
        {profileTitle !== (choosenProfile?.profiletitle || "") ? (
          <Button
            className="btn btn-primary btn-success"
            onClick={() => {
              if (choosenProfile) {
                setProfileTitle(choosenProfile.profiletitle);
                composeFormik.setFieldValue(
                  "updated_title",
                  choosenProfile.profiletitle
                );
              }
            }}
          >
            {t("Backend.Applications.Buttons.ResetProfileTitle")}
          </Button>
        ) : (
          <Button
            className="btn btn-primary btn-success"
            onClick={() => {
              if (application) {
                let modifiedString = removeGenderTags(application.job_title);
                setProfileTitle(modifiedString);
                composeFormik.setFieldValue("updated_title", modifiedString);
              }
            }}
          >
            {t("Backend.Applications.Buttons.UpdateProfileTitle")}
          </Button>
        )}
      </>
    );
  };

  useEffect(() => {
    const getProfile = async () => {
      let response = await crudApi.justGet(
        ProfileConfig.endpoints.show + composeFormik.values.profile.id
      );
      if (response.ok) {
        setChoosenProfile(response.data);
        setProfileTitle(response.data.profiletitle);
      }
    };

    switch (slideIndex) {
      case 0:
        setStepTitle(t("Backend.Applications.Titles.ChooseJob"));
        break;
      case 1:
        setStepTitle(t("Backend.Applications.Titles.ChooseProfile"));
        break;
      case 2:
        setStepTitle(t("Backend.Applications.Titles.CreateMail"));
        getProfile();
        break;
      case 3:
        setStepTitle(t("Backend.Applications.Titles.CheckAndSend"));
        break;
    }
  }, [slideIndex]);

  const Save = () => {
    return (
      <>
        {slideIndex > 0 && (
          <Button
            onClick={() => setSlideIndex(slideIndex - 1)}
            className="btn-light me-auto"
            style={{ fontWeight: "bold" }}
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </Button>
        )}

        {slideIndex === 3 && (
          <>
            <Button
              onClick={() => {
                if (isConnected) {
                  composeApplicationForm.onSubmit(
                    composeFormik.values,
                    composeFormik,
                    ApplicationConfig.endpoints.compose
                  );
                } else {
                  composeApplicationForm.onSubmit(
                    composeFormik.values,
                    composeFormik,
                    ApplicationConfig.endpoints.saveApplicaton
                  );
                  const mailtoLink = `mailto:${
                    application.mail_address
                  }?subject=${encodeURIComponent(
                    application.mail_subject
                  )}&body=${encodeURIComponent(application.mail_text)}`;

                  // Create a temporary anchor element
                  const a = document.createElement("a");
                  a.href = mailtoLink;
                  a.target = "_blank"; // Open in a new tab or window if possible
                  a.click(); // Programmatically trigger the click
                }
              }}
              className="btn btn-success btn btn-primary me-2"
            >
              <i className="psi-mail-send fs-3 me-2"></i>
              <b>{t("Backend.Applications.Buttons.Send")}</b>
            </Button>

            <Button
              onClick={() =>
                composeApplicationForm.onSubmit(
                  composeFormik.values,
                  composeFormik,
                  ApplicationConfig.endpoints.saveApplicaton
                )
              }
              className="btn btn-success btn btn-primary me-2"
            >
              <b>{t("Backend.Applications.Buttons.Save")}</b>
            </Button>
          </>
        )}

        {slideIndex === 2 && (
          <Button onClick={() => setStep3()} className="btn btn-success me-2">
            {t("Backend.Applications.Buttons.CheckAndSend")}
          </Button>
        )}
      </>
    );
  };

  const handleSlideLoad = () => {
    if (slideIndex === 1) {
      loadProfiles();
    }
    if (slideIndex === 2) {
      loadLetters();
    }
  };

  return (
    <>
      <LoadingModal
        showSpinner={showLoadingModal}
        setShowSpinner={setShowLoadingModal}
        text={t("Backend.Post.AIworking")}
      />
      <NewModal
        show={show}
        setShow={setShow}
        ExtraButton={Save}
        title={stepTitle}
        onExit={() => {
          setSlideIndex(0);
          composeFormik.resetForm();
          setProfiles([]);
          setChoosenProfile(null);
        }}
        onShow={() => {
          composeFormik.resetForm();
          loadTodoApplications();
        }}
      >
        <Carousel
          activeIndex={slideIndex}
          interval={null}
          controls={false}
          onSlid={handleSlideLoad}
          indicators={false}
        >
          <Carousel.Item>
            {Array.isArray(todoApplications) &&
              todoApplications.length > 0 &&
              todoApplications.map((application, index) => {
                return (
                  <ApplicationItem
                    application={application}
                    index={index}
                    key={application.id}
                    composeMode={true}
                    setStep1={setStep1}
                  />
                );
              })}
          </Carousel.Item>
          <Carousel.Item>
            <Row className="d-flex align-items-stretch">
              {Array.isArray(profiles) &&
                profiles.length > 0 &&
                profiles.map((profile, index) => {
                  return (
                    <Profile
                      profile={profile}
                      index={index}
                      composeMode={true}
                      setStep2={setStep2}
                      applicationId={application.id}
                      setPreviewId={setPreviewId}
                      setShowPreview={setShowPreview}
                      setProfileTitle={setPreviewProfileTitle}
                      UseButton={UseButton}
                    />
                  );
                })}
            </Row>
          </Carousel.Item>
          <Carousel.Item>
            <Form.Group className={"form-inline"}>
              <Row className="mb-3">
                <Col className="col-2 fw-bold pt-2">
                  {t("Backend.Applications.Mail.ChooseLetter")}
                </Col>
                <Col>
                  <Form.Group className={"form-inline"}>
                    <Form.Select
                      className={"custom-select form-control-border"}
                      onChange={(event) => {
                        setCheckResults([]);
                        const selectedText =
                          event.target.options[event.target.selectedIndex].text; // Get the text of the selected option

                        // Update Formik and perform actions based on the selected value
                        const currentLetter = letters.find(
                          (letter) => letter.title === selectedText
                        );

                        let firstName = application.recipient_name
                          ? application.recipient_name.split(" ")[0]
                          : "";
                        let lastName = application.recipient_name
                        ? application.recipient_name.split(" ")[1]
                        : "";
                        let salutation = application.recipient_salutation
                          ? t("Backend.Applications.Form.Salutation.Male")
                          : t("Backend.Applications.Form.Salutation.Female");

                        if (currentLetter) {
                          let modifiedLetter = currentLetter.text;
                          modifiedLetter = modifiedLetter.replace(
                            "{{firstname}}",
                            firstName
                          );
                          modifiedLetter = modifiedLetter.replace(
                            "{{lastname}}",
                            lastName
                          );
                          modifiedLetter = modifiedLetter.replace(
                            "{{salutation}}",
                            salutation
                          );
                          modifiedLetter = modifiedLetter.replace(
                            "{{portal}}",
                            application.page
                          );
                          modifiedLetter = modifiedLetter.replace(
                            "{{jobtitle}}",
                            application.job_title
                          );

                          composeFormik.setFieldValue(
                            "mail_text",
                            modifiedLetter
                          );
                        }
                      }}
                      style={{ width: "100%" }}
                    >
                      <option></option>
                      {letters.map((letter, index) => {
                        return <option key={index}>{letter.title}</option>;
                      })}
                    </Form.Select>
                  </Form.Group>
                </Col>
              </Row>
              <Row className="mb-3">
                <Col className="col-2 fw-bold pt-2">
                  {t("Backend.Applications.Mail.Subject")}
                </Col>
                <Col>
                  <TextField
                    formik={composeFormik}
                    valueName={"mail_subject"}
                    placeholder={"Subject"}
                  />
                </Col>
              </Row>
              <hr />
              <Row>
                <Col className="col-2 fw-bold pt-1">
                  {t("Backend.Applications.Mail.Text")}
                </Col>
              </Row>
              {
              <Row className="d-flex justify-content-end">
                <Col xs="auto">
                  {application && (
                    <AIDropdownMenu
                      setShowLoadingModal={setShowLoadingModal}
                      setTextCheckResult={setCheckResults}
                      setCreateResult={setAiLetter}
                      formik={composeFormik}
                      jobdescription={application.job_description}
                    />
                  )}
                </Col>
              </Row>}
              <Row>
                {checkResults.length > 0 && (
                  <Col>
                    {checkResults.map((result, index) => {
                      return (
                        <>
                          <Row className="mb-3">
                            <Row className="mb-3">
                              <Col>
                                <b>
                                  {t(
                                    "Backend.Applications.Mail.Changes.Change"
                                  )}
                                </b>
                              </Col>
                            </Row>
                            <Row>
                              <Col>{result.change}</Col>
                            </Row>
                          </Row>
                          <Row>
                            <Row className="mb-3">
                              <Col>
                                <b>
                                  {t(
                                    "Backend.Applications.Mail.Changes.Reason"
                                  )}
                                </b>
                              </Col>
                            </Row>
                            <Row>
                              <Col>{result.reason}</Col>
                            </Row>
                          </Row>
                          <hr />
                        </>
                      );
                    })}
                  </Col>
                )}
                {aiLetter.length > 0 && (
                  <>
                    <Col>
                      <Row className="mb-3">
                        <Col>
                          <Row className="mb-3">
                            <Col>
                              <b>{t("Backend.Applications.Mail.AiLetter")}</b>
                            </Col>
                          </Row>
                          <Row>
                            <Col
                              dangerouslySetInnerHTML={{
                                __html: aiLetter.replace("\\n", "<br/>"),
                              }}
                            />
                          </Row>
                        </Col>
                      </Row>
                      <Row>
                        <Col>
                          <Button
                            onClick={() => {
                              composeFormik.setFieldValue(
                                "mail_text",
                                aiLetter
                              );
                              setAiLetter("");
                            }}
                          >
                            {t("Backend.Applications.AI.Use")}
                          </Button>
                        </Col>
                      </Row>
                    </Col>
                  </>
                )}
                <Col>
                  <TextArea
                    formik={composeFormik}
                    valueName={"mail_text"}
                    rows={15}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Carousel.Item>
          <Carousel.Item>
            {application && (
              <ApplicationCheck
                setPreviewId={setPreviewId}
                setShowPreview={setShowPreview}
                setPreviewProfileTitle={setPreviewProfileTitle}
                application={application}
                profileTitle={profileTitle}
                choosenProfile={choosenProfile}
                fitColor={fitColor}
                UpdateProfileTitle={UpdateProfileTitle}
                composeFormik={composeFormik}
              />
            )}
          </Carousel.Item>
        </Carousel>
      </NewModal>
    </>
  );
};

export default ComposeApplicationModal;
