import NewModal from "../../../Shared/Modals/NewModal";
import { useTranslation } from "react-i18next";
import ApplicationCheck from "./ApplicationCheck";
import { Row, Col, Button, Tab } from "react-bootstrap";
import KeyValueForm from "@serie3/common/Form/shared/KeyValueForm";
import { useState, useEffect } from "react";
import { ApplicationConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import { useFormik } from "formik";
import TextArea from "../../../Shared/Form/TextArea";
import listHandler from "@serie3/common/Domain/listHandler";
import crudApi from "@serie3/common/api/crudApi";
import { formatDate } from "@serie3/common/Shared/timeingStuff";
import StyledTabs from "../../../Shared/Tabs/StyledTabs";
import ResponsiveTabTitle from "../../../Shared/Tabs/ResponsiveTabTitle";
import {
  faHistory,
  faEdit,
  faEarth,
  faCalendar,
  faNoteSticky,
} from "@fortawesome/free-solid-svg-icons";
import ApplicationFormComponent from "./ApplicationFormComponent";
import ApplicationForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/ApplicationForm";
import ApplicationComposeForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/ApplicationComposeForm";

const ViewApplication = ({
  show,
  setShow,
  viewApplication,
  setViewApplication,
  setShowPreview,
  setPreviewId,
  setPreviewProfileTitle,
  fitColor,
  setShowAppointment,
  setEditApplication,
  appointments,
  setAppointments,
  applications,
  setApplications,
  composeApplicationForm,
  composeFormik,
  isConnected,
}) => {
  const { t } = useTranslation();
  const [notes, setNotes] = useState([]);
  const [activeTabKey, setActiveTabKey] = useState("mail"); // Set the default active key
  const [application, setApplication] = useState(null);

  const noteForm = KeyValueForm.createKeyValueForm(
    notes,
    setNotes,
    null,
    null,
    ApplicationConfig.endpoints.notes.new,
    ApplicationConfig.endpoints.notes.edit
  );

  const noteFormik = useFormik(noteForm);

  const addNote = (e) => {
    noteFormik.setFieldValue("application.id", application.id);
    noteFormik.setFieldValue("key_name", "Comment");
    noteFormik.handleSubmit(e);
    e.preventDefault();
  };

  const editApplicationForm = ApplicationForm.createApplicationForm(
    applications,
    setApplications,
    setShow
  );
  const editApplicationFormik = useFormik(editApplicationForm);

  useEffect(() => {
    const getData = async () => {
      const response = await crudApi.justGet(
        ApplicationConfig.endpoints.show + viewApplication.id
      );
      if (response.ok) {
        setApplication(response.data);
        setNotes(response.data.notes);
        setAppointments(response.data.appointments);
      }
    };

    getData();
  }, [viewApplication.id]);

  const applicationTitle = application
    ? application.job_title + " | @" + application.hireing_company
    : "";

  const Buttons = ({ activeTabKey }) => {
    switch (activeTabKey) {
      case "basics":
        return (
          <Button
            className="btn-success"
            onClick={() => {
              editApplicationFormik.submitForm();
            }}
          >
            {t("Backend.Buttons.Save")}
          </Button>
        );
      case "mail":
        return (
          <>
            {!application.send && (
              <Button
                className="btn-success"
                onClick={() => {
                  if (isConnected) {
                    composeApplicationForm.onSubmit(
                      composeFormik.values,
                      composeFormik,
                      ApplicationConfig.endpoints.compose
                    );
                  } else {
                    composeApplicationForm.onSubmit(
                      composeFormik.values,
                      composeFormik,
                      ApplicationConfig.endpoints.saveApplicaton
                    );
                    const mailtoLink = `mailto:${
                      application.mail_address
                    }?subject=${encodeURIComponent(
                      application.mail_subject
                    )}&body=${encodeURIComponent(application.mail_text)}`;

                    // Create a temporary anchor element
                    const a = document.createElement("a");
                    a.href = mailtoLink;
                    a.target = "_blank"; // Open in a new tab or window if possible
                    a.click(); // Programmatically trigger the click
                  }
                }}
              >
                <i className="psi-mail-send fs-3 me-2"></i>
                <b>{t("Backend.Applications.Buttons.Send")}</b>
              </Button>
            )}
            <Button
              className="btn-success"
              onClick={() => {
                composeApplicationForm.onSubmit(
                  composeFormik.values,
                  composeFormik,
                  ApplicationConfig.endpoints.saveApplicaton
                );
              }}
            >
              <b>{t("Backend.Applications.Buttons.Save")}</b>
            </Button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    application && (
      <NewModal
        show={show}
        setShow={setShow}
        ExtraButton={() => <Buttons activeTabKey={activeTabKey} />}
        title={applicationTitle}
        onExit={() => {
          setViewApplication(null);
        }}
        onShow={() => {
          if (application) {
            editApplicationFormik.setValues(
              ApplicationForm.setEditData(application)
            );
            composeFormik.setValues(
              ApplicationComposeForm.setEditData(application)
            );
          }
        }}
      >
        <StyledTabs defaultKey="mail" handleKeyChange={setActiveTabKey}>
          <Tab
            eventKey="basics"
            title={
              <ResponsiveTabTitle
                icon={faEarth}
                fullTitle={t("Backend.Applications.Composer.Basics.Title")}
              />
            }
            className="nav nav-tabs"
          >
            <ApplicationFormComponent formik={editApplicationFormik} />
          </Tab>
          <Tab
            eventKey="mail"
            title={
              <ResponsiveTabTitle
                icon={faEdit}
                fullTitle={t("Backend.Applications.Composer.Mail.Title")}
              />
            }
            className="nav nav-tabs"
          >
            <ApplicationCheck
              composeFormik={composeFormik}
              setPreviewId={setPreviewId}
              setShowPreview={setShowPreview}
              setPreviewProfileTitle={setPreviewProfileTitle}
              application={application}
              profileTitle={
                application.profile && application.profile.profileTitle
              }
              choosenProfile={application.profile}
              fitColor={fitColor}
            />
          </Tab>
          <Tab
            eventKey="appointments"
            title={
              <ResponsiveTabTitle
                icon={faCalendar}
                fullTitle={t(
                  "Backend.Applications.Composer.Appointments.Title"
                )}
              />
            }
            className="nav nav-tabs"
          >
            <Row>
              <Col className="mt-3 mb-3">
                <Button
                  onClick={() => {
                    setShowAppointment(true);
                    setEditApplication(application);
                  }}
                >
                  {t("Backend.Applications.Composer.Appointments.AddButton")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="list-group">
                  {appointments &&
                    appointments
                      .slice()
                      .reverse()
                      .map((item, index, arr) => {
                        const originalIndex = arr.length - 1 - index;
                        return (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center"
                            key={item.id}
                          >
                            <b className="me-3">
                              {item.appointment_date.replace("T", " - ")}:
                            </b>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.value &&
                                  item.value.replace(/\n/g, "<br />"),
                              }}
                            />
                            <Button
                              className="btn btn-icon btn-sm btn-danger btn-hover ms-3 ms-auto"
                              onClick={() =>
                                listHandler.deleteFromList(
                                  originalIndex,
                                  crudApi.del(
                                    ApplicationConfig.endpoints.appointments
                                      .delete,
                                    item.id
                                  ),
                                  setAppointments,
                                  appointments
                                )
                              }
                            >
                              <i className="pli-trash fs-5"></i>
                            </Button>
                          </li>
                        );
                      })}
                </ul>
              </Col>
            </Row>
          </Tab>

          <Tab
            eventKey="notes"
            title={
              <ResponsiveTabTitle
                icon={faNoteSticky}
                fullTitle={t("Backend.Applications.Composer.Notes.Title")}
              />
            }
            className="nav nav-tabs"
          >
            <Row>
              <Col>
                <TextArea formik={noteFormik} rows={10} valueName={"value"} />
              </Col>
            </Row>
            <Row>
              <Col className="mt-3 mb-3">
                <Button
                  onClick={(e) => {
                    addNote(e);
                  }}
                >
                  {t("Backend.Applications.Composer.Notes.AddButton")}
                </Button>
              </Col>
            </Row>
            <Row>
              <Col>
                <ul className="list-group">
                  {notes &&
                    notes
                      .slice()
                      .reverse()
                      .map((item, index, arr) => {
                        const originalIndex = arr.length - 1 - index;
                        return (
                          <li
                            className="list-group-item d-flex justify-content-between align-items-center"
                            key={item.id}
                          >
                            <b className="me-3">{item.date}:</b>
                            <span
                              dangerouslySetInnerHTML={{
                                __html:
                                  item.value &&
                                  item.value.replace(/\n/g, "<br />"),
                              }}
                            />
                            <Button
                              className="btn btn-icon btn-sm btn-danger btn-hover ms-3 ms-auto"
                              onClick={() =>
                                listHandler.deleteFromList(
                                  originalIndex,
                                  crudApi.del(
                                    ApplicationConfig.endpoints.notes.delete,
                                    item.id
                                  ),
                                  setNotes,
                                  notes
                                )
                              }
                            >
                              <i className="pli-trash fs-5"></i>
                            </Button>
                          </li>
                        );
                      })}
                </ul>
              </Col>
            </Row>
          </Tab>
          <Tab
            eventKey="history"
            title={
              <ResponsiveTabTitle
                icon={faHistory}
                fullTitle={t("Backend.Applications.Composer.Timeline.Title")}
              />
            }
            className="nav nav-tabs"
          >
            <Row>
              <Col>
                <ul className="list-group">
                  {application.application_status &&
                    application.application_status
                      .slice()
                      .reverse()
                      .map((item) => {
                        return (
                          <li className="list-group-item" key={item.id}>
                            <b>{formatDate(item.created, true)}:</b>{" "}
                            {t(
                              "Backend.Applications.Status." +
                                item.status.charAt(0).toUpperCase() +
                                item.status.slice(1)
                            )}
                          </li>
                        );
                      })}
                </ul>
              </Col>
            </Row>
          </Tab>
        </StyledTabs>
      </NewModal>
    )
  );
};

export default ViewApplication;
