import { Routes, Route } from "react-router-dom";
import CoverLetterIndex from "./CoverLetterIndex";
import Notfound from "../../Shared/Notfound";

const CoverLetterRouter = () => {

    return (
        <Routes>
            <Route path="/" element={<CoverLetterIndex />} />
            <Route path="*" element={<Notfound />} />
        </Routes>
    )
}

export default CoverLetterRouter;