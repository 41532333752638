import { Container, Row, Col, Image } from "react-bootstrap";
import { Trans } from "react-i18next";
import SectionImage from "@serie3/bewerbungshelferleinapi/assets/AiSection.webp";

const AISection = () => {
  return (
    <Container className="my-5 landingpageSection">
      <Row className="text-center">
        <Col md={9} className="mx-auto">
          <h1>
            <Trans i18nKey="Landingpage.AiSection.DetailsHeader">
              Don't feel like writing  <span style={{ color: "#8cd82e" }}>job applications? </span>
              Kein Bock aufBewerbung schreiben?
            </Trans>
          </h1>
          <p className="mt-3 boost">
            <Trans i18nKey="Landingpage.AiSection.MotivationQuote" />
          </p>
        </Col>
      </Row>

      <Row className="align-items-center mt-5">
        <Col md={6} className="text-center">
        <iframe width="560" height="315" src="https://www.youtube.com/embed/7hkJPCWkl4c?si=X-4sMwwoIRxACBeq" title="Der Bewerbungshelferlein Bewerbungsassisten: Bewerbungen in weniger als 90 Sekunden verschicken" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen></iframe>
        </Col>

        <Col md={5} className="ms-md-5 ps-md-5 ms-0 ps-0">
          <h2>
            <strong><Trans i18nKey="Landingpage.AiSection.Title"/></strong>
          </h2>
          <h5 className="mt-3"><Trans i18nKey="Landingpage.AiSection.Subtitle"/></h5>
          <p className="mt-3 text">
            <Trans i18nKey="Landingpage.AiSection.Text"/>
          </p>
        </Col>
      </Row>
    </Container>
  );
};

export default AISection;
