import Header from "../Shared/Basics/Header";
import Dashboard from "./Dashboard";
import Nav from "./Nav";
import { Routes, Route } from "react-router-dom";
import ProfileRouter from "./Profiles/ProfileRouter";
import Settings from "./user/Settings";
import Notfound from "../Shared/Notfound";
import Error from "../Shared/Error";
import { useState, useEffect } from "react";
import SettingsContext from "@serie3/common/auth/SettingsContext";
import fetchSettings from "@serie3/common/Domain/fetchSettings";
import Callbacks from "./user/Callbacks";
import Jobmails from "./user/Jobmails";
import ApplicationWrapper from "./user/ApplicationWrapper";
import Archive from "./Applications/Archive";
import CoverLetterRouter from "./CoverLetter/CoverLetterRouter";

const BackendRouter = () => {
  const [settings, setSettings] = useState(null);

  useEffect(() => {
    fetchSettings(setSettings);
  }, []);

  return (
    <SettingsContext.Provider value={{ settings, setSettings }}>
      <Header sidebar={true} />
      <Nav />
      <Routes>
        <Route path="/" element={<Dashboard />} />
        <Route path="/uuuups" element={<Error />} />
        <Route path="/profile/*" element={<ProfileRouter />} />
        <Route path="/coverletter/*" element={<CoverLetterRouter />} />
        <Route path="/settings/*" element={<Settings />} />
        <Route path="/callbacks/*" element={<Callbacks />} />
        <Route path="/jobmails/*" element={<Jobmails />} />
        <Route path="/applications/*" element={<ApplicationWrapper />} />
        <Route path="/archive/*" element={<Archive />} />
        <Route path="*" element={<Notfound />} />
      </Routes>
    </SettingsContext.Provider>
  );
};

export default BackendRouter;
