import { Row, Col } from "react-bootstrap";
import TextField from "../../../Shared/Form/TextField";
import { useTranslation } from "react-i18next";
import SelectField from "../../../Shared/Form/SelectField";
import TextArea from "../../../Shared/Form/TextArea";

const ApplicationFormComponent = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col>
          <h4 className="mb-3 mt-2">
            {t("Backend.Applications.Form.ContactTitle")}
          </h4>
        </Col>
      </Row>
      <Row>
        <Row>
          <Col>
            <SelectField
              formik={formik}
              label={t("Backend.Applications.Form.RecipientSalutation")}
              valueName={"recipient_salutation"}
              style={{ marginBottom: "10px" }}
              value={formik.values.recipient_salutation}
            >
              <option></option>
              <option value="male">
                {t("Backend.Applications.Form.Salutation.Male")}
              </option>
              <option value="female">
                {t("Backend.Applications.Form.Salutation.Female")}
              </option>
            </SelectField>
          </Col>
          <Col>
            <TextField
              formik={formik}
              label={t("Backend.Applications.Form.RecipientName")}
              valueName={"recipient_name"}
              style={{ marginBottom: "10px" }}
            />
          </Col>
        </Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Applications.Form.MailAddress")}
            valueName={"mail_address"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <h4 className="mb-3 mt-3">
            {t("Backend.Applications.Form.JobDetailsTitle")}
          </h4>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Applications.Form.Jobtitle")}
            valueName={"job_title"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Applications.Form.Hireingcompany")}
            valueName={"hireing_company"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Applications.Form.Page")}
            valueName={"page"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Applications.Form.Joblink")}
            valueName={"link"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.Applications.Form.Worklocation")}
            valueName={"location"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <TextArea
            formik={formik}
            label={t("Backend.Applications.Form.JobDescription")}
            valueName={"job_description"}
            rows={15}
          />
        </Col>
      </Row>
    </>
  );
};

export default ApplicationFormComponent;
