/** Calculates how many applications are in which state */
const calculateDashboardNumbers = (applications = []) => {
    const numbers = {
        todo: 0,
        running: 0,
        done: 0
    }
    applications.length > 0 && applications.forEach(application => {
        switch(application.status.toLowerCase()){
            case "todo": 
                numbers.todo++;
                break;
            case "accepted":
            case "rejected":
            case "ready":
                numbers.done++;
                break;
            default:
                numbers.running++;
        }
        
    });
    
    return numbers;
};


/** Calculates how many applications are in which state */
const calculateArchiveNumbers = (applications = []) => {
    const numbers = {
        noreply: 0,
        rejected: 0,
        canceled: 0,
        accepted: 0,
    }
    applications.length > 0 && applications.forEach(application => {
        switch(application.status.toLowerCase()){
            case "noreply": 
                numbers.noreply++;
                break;
            case "accepted":
                numbers.accepted++;
                break;
            case "rejected":
                numbers.rejected++;
                break;
            case "canceled":
                numbers.canceled++;
                break;
        }
    });
    
    return numbers;
};

export {calculateDashboardNumbers, calculateArchiveNumbers};
