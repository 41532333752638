import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import { useTranslation } from "react-i18next";
import Card from "../../Shared/Card";
import ApplicationHeader from "./Applications/ApplicationHeader";
import { Row, Col, Modal } from "react-bootstrap";
import { useEffect, useState } from "react";
import LoadApplications from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/LoadApplications";
import ApplicationItem from "./Applications/ApplicationItem";
import ComposeApplicationModal from "../Applications/Subcomponents/ComposeApplicationModal";
import {
  deleteAction,
  editAction,
  removeFromList,
} from "@serie3/bewerbungshelferleinapi/Modules/Applications/Domain/ApplicationCrudActions";
import ApplicationModal from "../Applications/Subcomponents/ApplicationModal";
import PreviewModal from "../../Shared/Modals/PreviewModal";
import { ProfileConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import UserConfig from "@serie3/bewerbungshelferleinapi/Modules/User/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";
import ViewApplication from "../Applications/Subcomponents/ViewApplication";
import AppoinmentModal from "../Applications/Subcomponents/AppointmentModal";
import ApplicationComposeForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/ApplicationComposeForm";
import { useFormik } from "formik";

const Applications = () => {
  const { t } = useTranslation();
  const [applications, setApplications] = useState([]);
  const [updateView, setUpdateView] = useState(false);
  const [showComposeApplication, setShowComposeApplication] = useState(false);
  const [showForm, setShowForm] = useState(false);
  const [editApplication, setEditApplication] = useState(null);
  const [showPreview, setShowPreview] = useState(false);
  const [previewId, setPreviewId] = useState(-1);
  const [profileTitle, setProfileTitle] = useState("");
  const [isConnected, setIsConnected] = useState(false);
  const [viewApplication, setViewApplication] = useState();
  const [showViewApplication, setShowViewApplication] = useState(false);
  const [showAppointment, setShowAppointment] = useState(false);
  const [appointments, setAppointments] = useState([]);
  const [showSpinner, setShowSpinner] = useState(false);

  const composeApplicationForm = ApplicationComposeForm.createApplicationForm(
    applications,
    setApplications,
    setShowComposeApplication,
    setShowSpinner
  );
  const composeFormik = useFormik(composeApplicationForm);

  useEffect(() => {
    LoadApplications(setApplications);
    const isConnected = async () => {
      let response = await crudApi.justGet(
        UserConfig.endpoints.social.gmail.isConneted
      );
      if (response.ok && response.data.connected) {
        setIsConnected(true);
      }
    };

    isConnected();
  }, []);

  const StartApplicationButton = () => {
    return (
      <button
        type="button"
        className="btn btn-info hstack gap-3"
        onClick={() => setShowComposeApplication(true)}
      >
        <i className="psi-file-edit fs-4"></i>
        <span className="vr"></span>
        {t("Backend.Applications.Startbutton")}
      </button>
    );
  };

  const SpinnerModal = () => {
    return (
      <Modal show={showSpinner} setShow={setShowSpinner}>
        <Modal.Body
          style={{ textAlign: "center", backgroundColor: "transparent" }}
        >
          <div className="spinner-border" role="status">
            <span className="sr-only">{t("Backend.Applications.Loading")}</span>
          </div>
          <div> {t("Backend.Applications.Save")}</div>
        </Modal.Body>
      </Modal>
    );
  };

  return (
    <>
      <SpinnerModal />
      <AppoinmentModal
        show={showAppointment}
        setShow={setShowAppointment}
        appointments={appointments}
        setAppointments={setAppointments}
        application={editApplication}
      />

      <ComposeApplicationModal
        show={showComposeApplication}
        setShow={setShowComposeApplication}
        setShowPreview={setShowPreview}
        setPreviewId={setPreviewId}
        setPreviewProfileTitle={setProfileTitle}
        isConnected={isConnected}
        composeApplicationForm={composeApplicationForm}
        composeFormik={composeFormik}
      />

      <ApplicationModal
        show={showForm}
        setShow={setShowForm}
        applications={applications}
        setApplications={setApplications}
        application={editApplication}
      />

      <PreviewModal
        show={showPreview}
        setShow={setShowPreview}
        id={previewId}
        setId={setPreviewId}
        title={profileTitle}
        downloadUrl={ProfileConfig.endpoints.download + previewId}
      />

      {viewApplication && (
        <ViewApplication
          show={showViewApplication}
          setShow={setShowViewApplication}
          viewApplication={viewApplication}
          setViewApplication={setViewApplication}
          setPreviewProfileTitle={setProfileTitle}
          setPreviewId={setPreviewId}
          setShowPreview={setShowPreview}
          fitColor={"#9fcc2e"}
          setShowAppointment={setShowAppointment}
          setEditApplication={setEditApplication}
          appointments={appointments}
          setAppointments={setAppointments}
          applications={applications}
          setApplications={setApplications}
          composeApplicationForm={composeApplicationForm}
          composeFormik={composeFormik}
          isConnected={isConnected}
        />
      )}

      <ContentWrapper
        title={t("Backend.Settings.Title")}
        welcomeText={t("Backend.Settings.Subtitle")}
        titleText={t("Backend.Settings.SettingsText")}
      >
        <ApplicationHeader Button={StartApplicationButton} />
        <Row>
          <Col>
            <Card>
              <div className="d-md-flex gap-4">
                <div className="flex-fill min-w-0">
                  <div className="list-group list-group-borderless gap-1 mb-3">
                    {Array.isArray(applications) &&
                      applications.length > 0 &&
                      applications.map((application, index) => {
                        return (
                          <ApplicationItem
                            index={index}
                            application={application}
                            key={application.id}
                            updateView={updateView}
                            setUpdateView={setUpdateView}
                            deleteAction={() => {
                              deleteAction(
                                application.id,
                                index,
                                setApplications,
                                applications
                              );
                            }}
                            setShowViewApplication={setShowViewApplication}
                            setViewApplication={setViewApplication}
                            setShowComposeApplication={
                              setShowComposeApplication
                            }
                            setShowForm={setShowForm}
                            setShowAppointment={setShowAppointment}
                            setEditApplication={setEditApplication}
                            removeFromList={() => {
                              removeFromList(
                                application,
                                applications,
                                setApplications
                              );
                            }}
                          />
                        );
                      })}
                  </div>
                </div>
              </div>
            </Card>
          </Col>
        </Row>
      </ContentWrapper>
    </>
  );
};

export default Applications;
