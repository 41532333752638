import { useTranslation } from "react-i18next";
import { Row, Col, Dropdown, DropdownDivider } from "react-bootstrap";
import EllipsisVerticalToggle from "../../../Shared/Dropdown/EllipsisVerticalToggle";
import { faMicrochip } from "@fortawesome/free-solid-svg-icons";
import { CoverLetterConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import crudApi from "@serie3/common/api/crudApi";

const AIDropdownMenu = ({
  setShowLoadingModal,
  setTextCheckResult,
  setCreateResult,
  formik,
  jobdescription,
}) => {
  const { t } = useTranslation();

  const handleCheck = (response) => {
    setShowLoadingModal(false);
    setTextCheckResult(response.data.changes);
    setCreateResult("");
  };

  const handleCreate = (response) => {
    setTextCheckResult([]);
    setCreateResult(response.data);
    setShowLoadingModal(false);
  };

  const aiCall = async (promt_type, callBack) => {
    setShowLoadingModal(true);
    const dataObject = {
      jobdescription: jobdescription,
      coverletter: formik.values.mail_text,
      prompt_type: promt_type,
      profile_id: formik.values.profile.id,
    };
    const response = await crudApi.edit(
      CoverLetterConfig.endpoints.ai.help,
      dataObject
    );
    setShowLoadingModal(false);
    if (response.ok) {
      callBack(response);
    }
  };

  return (
    <>
      <Row>
        <Col className="text-end">
          <Dropdown style={{ float: "right" }}>
            <EllipsisVerticalToggle icon={faMicrochip} />
            <Dropdown.Menu>
              <Dropdown.Item
                onClick={() => {
                  aiCall("coverlettercheck", handleCheck);
                }}
              >
                <i className="pli-pen-5 fs-5 me-2" />
                {t("Backend.Applications.AI.CheckCoverLetter")}
              </Dropdown.Item>
              <DropdownDivider />
              <>
                <Dropdown.Item
                  onClick={() => {
                    jobdescription
                      ? aiCall("coverlettercreate", handleCreate)
                      : aiCall("coverletterprofileonly", handleCreate);
                  }}
                >
                  <i className="pli-pen-5 fs-5 me-2" />
                  {t("Backend.Applications.AI.WriteCoverLetter")}
                </Dropdown.Item>
                <DropdownDivider />
              </>
            </Dropdown.Menu>
          </Dropdown>
        </Col>
      </Row>
    </>
  );
};

export default AIDropdownMenu;
