import ContentWrapper from "../../Shared/Basics/ContentWrapper";
import { useTranslation } from "react-i18next";
import { Row, Col, Card } from "react-bootstrap";
import NewButtonWithIcon from "../../Shared/Buttons/NewButtonWithIcon";
import { useEffect, useState } from "react";
import NewModal from "../../Shared/Modals/NewModal";
import crudApi from "@serie3/common/api/crudApi";
import { CoverLetterConfig } from "@serie3/bewerbungshelferleinapi/Modules/Profile/Resources/Endpoints";
import CoverLetterFormView from "./CoverLetterFormView";
import CoverLetterForm from "@serie3/bewerbungshelferleinapi/Modules/Profile/Forms/CoverLetterForm";
import { useFormik } from "formik";
import SaveButton from "../../Shared/Buttons/SaveButton";
import CoverLetter from "@serie3/bewerbungshelferleinapi/Modules/Profile/Entities/CoverLetter";
import listHandler from "@serie3/common/Domain/listHandler";
import CompleteDropdown from "../../Shared/Dropdown/CompleteDropdown";

const CoverLetterIndex = () => {
  const { t } = useTranslation();
  const [showPayModal, setShowPayModal] = useState(false);
  const [editLetter, setEditLetter] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [letters, setLetters] = useState([]);

  useEffect(() => {
    const endpoint = CoverLetterConfig.endpoints.getAll;
    crudApi.getAll(endpoint, setLetters, "coverletter");
  }, []);

  const editCoverLetterForm = CoverLetterForm.createCoverletterForm(
    letters,
    setLetters,
    setShowEditModal
  );

  const formik = useFormik(editCoverLetterForm);

  const SaveForm = () => {
    return <SaveButton formik={formik} />;
  };

  return (
    <>
      <NewModal
        show={showEditModal}
        setShow={setShowEditModal}
        title={t("Backend.CoverLetter.Modal.Title")}
        ExtraButton={SaveForm}
        onShow={() => {
          editLetter
            ? formik.setValues(CoverLetterForm.setEditData(editLetter))
            : formik.setValues(CoverLetter);
        }}
        onExit={() => {
          formik.resetForm();
        }}
      >
        <CoverLetterFormView formik={formik} />
      </NewModal>

      <ContentWrapper
        title={t("Backend.CoverLetter.Index.Title")}
        welcomeText={t("Backend.CoverLetter.Index.Subtitle")}
        titleText={t("Backend.CoverLetter.Index.DashboardText")}
      >
        <Row>
          <Col>
            <div className="mt-4 mb-3 d-flex flex-wrap gap-2">
              <NewButtonWithIcon
                title={t("Backend.Buttons.NewCoverLetter")}
                showPayModal={showPayModal}
                setShowPayModal={setShowPayModal}
                color={"info"}
                handleClick={() => {
                  setEditLetter(null);
                  setShowEditModal(true);
                }}
                icon="psi-file-upload"
              />
            </div>
          </Col>
        </Row>
        <Row>
          {letters.length > 0 &&
            letters.map((letter, index) => {
              return (
                <Col>
                  <Card>
                    <Card.Header>
                     <b>{letter.title}</b>
                      <CompleteDropdown
                        editAction={() => {
                          setEditLetter(letter);
                          setShowEditModal(true);
                        }}
                        deleteAction={() => {
                          listHandler.deleteFromList(
                            index,
                            crudApi.del(
                              CoverLetterConfig.endpoints.delete + letter.id
                            ),
                            setLetters,
                            letters
                          );
                        }}
                      />
                    </Card.Header>
                    <Card.Body dangerouslySetInnerHTML={{ __html: letter.text.replace(/\n/g, "<br />") }} />
                    <Card.Footer></Card.Footer>
                  </Card>
                </Col>
              );
            })}
        </Row>
      </ContentWrapper>
    </>
  );
};

export default CoverLetterIndex;
