import { Row, Col } from "react-bootstrap";
import TextArea from "../../Shared/Form/TextArea";
import TextField from "../../Shared/Form/TextField";
import SelectField from "../../Shared/Form/SelectField";
import { useTranslation } from "react-i18next";

const CoverLetterFormView = ({ formik }) => {
  const { t } = useTranslation();
  return (
    <>
      <Row>
        <Col>
          <TextField
            formik={formik}
            label={t("Backend.CoverLetter.Form.Title")}
            valueName={"title"}
            style={{ marginBottom: "10px" }}
          />
        </Col>
      </Row>
      <Row>
        <Col>
          <SelectField
            formik={formik}
            label={t("Backend.CoverLetter.Form.Type")}
            valueName={"formal"}
            value={Number(formik.values.formal)} // Normalize value for the frontend
          >
            <option></option>
            <option value="1">
              {t("Backend.CoverLetter.Form.Formal")}</option>
            <option value="0">
              {t("Backend.CoverLetter.Form.Informal")}
            </option>
          </SelectField>
        </Col>
        <Col>
          <SelectField
            formik={formik}
            label={t("Backend.CoverLetter.Form.Language")}
            valueName={"cover_letter_language"}
            value={formik.values.cover_letter_language}
          >
            <option></option>
            <option value="de">{t("Backend.CoverLetter.Form.DE")}</option>
            <option value="en">{t("Backend.CoverLetter.Form.EN")}</option>
          </SelectField>
        </Col>
      </Row>
      <Row>
        <Col>
          <TextArea
            formik={formik}
            label={t("Backend.CoverLetter.Form.Text")}
            valueName={"text"}
            rows={20}
          />
        </Col>
      </Row>
      <Row>
        <Col className="mt-3">{t("Backend.CoverLetter.Help")}</Col>
      </Row>
    </>
  );
};

export default CoverLetterFormView;
